/*
 * CUSTOM STYLES
 *
 * All of your custom css classes and styles can be placed below.
 * Do NOT forgot to add comments above the different sections of styles.
 * For instance: styles that refer to news, add the comment /* NEWS * / above.
 *               styles that refer to product detail page, add the comment /* PRODUCT DETAIL * /
 *               Etc.
 */

.no-padding-top{padding-top: 0;}
.no-padding-left{padding-left: 0;}
.no-padding-bottom{padding-bottom: 0;}
.no-margin-bottom{margin-bottom: 0;}
.margin-bottom{margin-bottom: 2rem;}
.text-left-default{text-align: left !important;}
.no-border{border: none;}
.radius{border-radius: $global-radius;}
.radius-top{border-radius: $global-radius $global-radius 0 0;}
.radius-bottom{border-radius: 0 0 $global-radius $global-radius;}
.section-slider{padding-left:0; padding-right:0;}
.section-pb-4{padding-bottom: rem-calc(40);}
.section-mt-4{margin-top: rem-calc(40);}

// Text colors
.white{color: $white;}
.blue{color: $blue;}
.gray{color: $gray;}
.dark-gray{color: $dark-gray;}
.green{color: $green;}

// Background colors
.bg-white{background: $white;}
.bg-light-gray{background: scale-color($light-gray, $lightness: 65%);}
.bg-gray{background: $gray;}
.bg-blue{background: $blue;}
.bg-green{background: $green;}
.bg-light-blue{background: $light-blue;}

// Declaration content
.page-content{
    .callout{text-align:center;}
}

.btn-delete{
    color:$anchor-color;
    &:hover{color:$anchor-color-hover}
}

.confirm-wrapper{
    padding:20px;
    .button-group .button{margin-bottom:0;}
}

.export-link{
    margin-left:5px; padding:2px 5px;
    display:inline-block;
}
.icn-export-link{font-size:1.8rem;}

// Submenu
.submenu-wrapper{
    margin-left:0; margin-bottom:3rem;
    list-style-type:none;
}
.submenu-item{
    padding:0 20px;
    position:relative;
    display:inline-block;
    &::after{
        width:1px; height:15px;
        position:absolute; z-index:5; top:5px; right:0;
        background-color:$secondary-color;
        content:'';
        display:block;
    }
    &:last-child{
        &::after{display:none;}
    }
}
.submenu-anchor{
    padding:3px 0;
    position:relative; z-index:1;
    &.is-active{
        &::after{
            width:100%; height:1px;
            position:absolute; z-index:5; left:0; bottom:0;
            background-color:$primary-color;
            content:'';
            display:block;
        }
    }
}

.badge-wrapper{
    display: block;
    height: 75px;
    width: 75px; 
    margin: 10px auto 0 auto;  
    padding: 0 10px;
    border: 3px solid $green;
    border-radius: 50%;
    font-size: 2.2rem;
    .badge{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

// Accordion styling
.accordion-anchor{
    margin-bottom:rem-calc(15);
    font-size:1.8rem; color:$primary-color;
}

// Maps wrapper
.maps-wrapper{
    max-width:865px; width:100%; height:150px;
    background-image:url('/assets/images/frontend/img_google_maps.jpg'); background-repeat:no-repeat; background-position:center center; border-radius:1rem;
    display:block; 
}

// Ajax loader
.ajax-image-loader{
    width:rem-calc(75); height:rem-calc(75); margin:auto;
}

// Vacancy
.vacancy-description{font-size:1.5rem; line-height:1.4;}


//Products
.product-item{
    .call-to-action{
        color: $green;
    }
}

.product-item-large{
    .section-title{
        margin-bottom: 0;
    }
}

.product-content{
    padding-bottom: 2rem;margin-bottom: 2rem;border-bottom: 1px solid lighten($green, 20%);
}

.credits{
    font-size: 1.8rem;
}

.disabled-text{
    display: block;font-size: 1.4rem;
}

.fallback-container{
    background-color: darken($gray, 20%);width: 100%;height: 170px;
    &.large{
        height: 169px;width: 100%;
    }
}

// HERO
.hero-button-wrapper{
    margin-top: 10rem;
}

.hero-team{
    .padding-top{
        padding-top: 3rem;
    }
}

.hero-intro{
    text-align: center;
    font-size: 2.2rem;
    font-weight: 500;
}

.hero-slider-image{
    margin:0 auto;
}

.cookie-wrapper{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    background: transparentize($light-gray, 0.2);
    z-index: 9999;
    display: flex;
    align-items: center;

    .cookie {
        width: 100%;
        max-height: 90%;
        overflow-y: auto;
        background-color: $white;
        padding: 2rem 0;
    }
}

.cookie-inner-wrapper {
    input[type='radio'] {
        color: $dark-gray;
        outline-color: $dark-gray;
        position: relative;
        -webkit-appearance:none;
        width:24px;
        height:24px;
        border-radius:50%;
        outline:none;
        margin-right: 1rem;
        cursor: pointer;

        &:checked {
            color: $white;
            background-color: $blue;
            &::after {
                border: none;
            }
        }

        &::before {
            z-index: 99;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }

        &::after {
            width: 2.4rem;
            height: 2.4rem;
            border: 1px solid $dark-gray;
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 50%;
        }
    }

    .content-wrapper {
        display: flex;
        flex-direction: row;
    }

    .radio-buttons-wrapper {
        display: flex;
        flex-direction: row;
    }

    .is-mobile {
        margin-bottom: 1.2rem;
    }
}

.disable-scroll {
    width: 100%;
    height: 100%;
    position: fixed!important;
    overflow: hidden!important;
}

.video-disabled {
    margin-top: 3rem;
    .video-disabled-placeholder {
        width: 100%;
        height: 30rem;
        background-color: $medium-gray;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .placeholder-icon {
        width: 4.8rem;
    }
}

.footer-small-logo {
    margin-bottom: 2rem;
}

.social-txt {
    padding: 0 0.6rem;
}

/* Media query min-width: 580 */
@include breakpoint(medium) {
    .fallback-container{
        height: 291px;
        &.large{
            height: 200px;
        }
    }
    .hero-intro{
        text-align: left;
        padding-top: 0;
    }

    .hero-slider-image{
        margin:0;
    }

    .cookie-wrapper {
        .cookie {
            height: auto;
            overflow: unset;
        }
    }
    .cookie-inner-wrapper {
        .is-mobile {
            margin-bottom: 0;
        }
    }

}

/* Media query min-width: 960 */
@include breakpoint(large) {
    .padding-top-xl{padding-top:12rem;}
    .fallback-container{
        height: 264px;
        &.large{
            height: 290px;
        }
    }
    .hero-intro-wrapper{
        display: flex;
    }
}

/* Media query min-width: 1200 */
@include breakpoint(xlarge) {
    .fallback-container{
        height: 345px;
        &.large{
            height: 371px;
        }
    }
}

/* Media query min-width: 1600 */
@include breakpoint(xxlarge) {
    .fallback-container{
        height: 357px;
    }
}
