/*
 * FOUNDATION OVERWRITES
 *
 * Sometimes using your own classes to style Foundation components isn't enough.
 * You'll need to overwrite the default style of Foundation by using their classes.
 * Place those classes in this file.
 */

$body-font-size: rem-calc(16);

body{font-size: $body-font-size;}
p{font-size: $body-font-size;}
ul, ol, dl{font-size: $body-font-size;}

/* zf Switch */
.switch-active, .switch-inactive{
    width:55%;
    text-align:center;
    display:inline-block;
}
.switch-active{left:0;}
.switch-inactive{right:0;}

/* fz Breadcrumbs */
.breadcrumbs {
    display: inline-block;
    text-align: center;
    margin: rem-calc(10) 0;
    li {
        &:not(:last-child)::after{color:$dark-gray;}
    }
}

.crumb{
    &.green{
        color: $green;
    }
}

/* fz Reveal */
.reveal {
    outline: none;
    border: none;
}
.reveal-overlay{
    -webkit-overflow-scrolling:touch;
    overflow-y:auto;
}

/* fz Tables */
.table-scroll{
    margin-top:rem-calc(10); margin-bottom:rem-calc(40);
    .table-wrapper{
        width:100%; margin-bottom:0;
        tbody{border:0;}
        .button{margin-bottom:0;}
    }
}

/* Select2 */
.select2-container {
    margin: 0 0 $form-spacing;
    .select2-selection{
        @include form-select();
        margin-bottom:0;
        .select2-selection__rendered{
            padding:0; 
            line-height:inherit; 
        }
        .select2-selection__arrow{display:none;}
    }
}