/**
 * Foundation for Sites by ZURB
 * Version 6.1.1
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */

// Import Fonts
@import url('https://fonts.googleapis.com/css?family=Cabin:400,500');

// Import settings_frontend.scss. For overwriting foundation variables
@import 'settings';

// Import all Foundation components
@import '../../../../node_modules/foundation-sites/scss/foundation.scss';

// Import fonts
@import 'fonts';

// Import Slick carousel
@import '../../../../node_modules/slick-carousel/slick/slick.scss';

// Import Lightcase
@import '../../../../node_modules/lightcase/src/scss/lightcase.scss';

// Include the right mixins
@include foundation-global-styles;
@include foundation-grid;
// @include foundation-flex-grid;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
@include foundation-visibility-classes;
@include foundation-float-classes;
// @include foundation-accordion;
// @include foundation-accordion-menu;
// @include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-close-button;
@include foundation-drilldown-menu;
// @include foundation-dropdown;
@include foundation-dropdown-menu;
// @include foundation-flex-video;
@include foundation-label;
// @include foundation-media-object;
@include foundation-menu;
@include foundation-menu-icon
// @include foundation-off-canvas;
// @include foundation-orbit;
// @include foundation-pagination;
// @include foundation-progress-bar;
// @include foundation-slider;
// @include foundation-sticky;
@include foundation-reveal;
// @include foundation-switch;
@include foundation-table;
// @include foundation-tabs;
// @include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;

// For grouping your css in mixins
@import 'mixins';
// To overwrite default Foundation style
@import 'overwrites';
// General classes for returning styles
@import 'general_styles';
// Animations classes
@import 'animations';

// Import custom style
@import 'custom';

// Import custom style
@import 'web_print';