/*
 * ANIMATION STYLES
 *
 * General animations that can be reused through out the website
 */

.scale{
    transition: transform 0.5s ease 0s;
    &:hover{transform: scale(1.1);}
}

.is-fade{
    transition: opacity .5s ease;
    opacity: 0;
}

.fade-in{opacity: 1;}
