/*
 * PRINT STYLES
 *
 * Styles for printing
 */
@media print {
    html, body {
      max-width: 210mm;
      max-height: 297mm;
    }

    a[href]:after{content: none !important;}

    p{font-size: 14px;}

    .large-1{width: 8.33333%;}
    .large-2{width: 16.66667%;}
    .large-3{width: 25%;}
    .large-4{width: 33.33333%;}
    .large-5{width: 41.66667%;}
    .large-6{width: 50%;}
    .large-7{width: 58.33333%;}
    .large-8{width: 66.66667%;}
    .large-9{width: 90%;}
    .large-10{width: 83.33333%;}
    .large-11{width: 91.66667%;}
    .large-12{width: 100%;}

    .logo-print{
        width: 100px;
        display: inline-block;
        img{width:100%;}
    }

    .header-item{
        height:auto; margin-top:23px; margin-left:20px; margin-bottom:40px;
        display:inline-block;
    }

    .section{padding: 0;}
}

@page {
    size: A4;
    margin: 2cm 1cm;
}
