@charset 'UTF-8';

// Front-end icons

@font-face {
    font-family: 'uvv';
    src:url('../fonts/icons/uvv.eot');
    src:url('../fonts/icons/uvv.eot?#iefix') format('embedded-opentype'),
    url('../fonts/icons/uvv.woff') format('woff'),
    url('../fonts/icons/uvv.ttf') format('truetype'),
    url('../fonts/icons/uvv.svg#uvv') format('svg');
    font-weight: normal;
    font-style: normal;

}

[class^='icn-']:before,
[class*=' icn-']:before {
  font-family: 'uvv' !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icn-facebook:before{content: '\61';}
.icn-twitter:before{content: '\62';}
.icn-envelope:before{content: '\63';}
.icn-arrow-61:before{content: '\64';}
.icn-arrow-62:before{content: '\65';}
.icn-print:before{content: '\66';}
.icn-file-text-o:before{content: '\67';}
.icn-instagram:before{content: '\68';}
.icn-calendar-1:before{content: '\69';}
.icn-check:before { content: '\6a'; }
.icn-times:before { content: '\6b'; }

// Admin icons

@font-face {
    font-family: 'clickadmin';
    src:url('../fonts/icons/clickadmin.eot');
    src:url('../fonts/icons/clickadmin.eot?#iefix') format('embedded-opentype'),
    url('../fonts/icons/clickadmin.woff') format('woff'),
    url('../fonts/icons/clickadmin.ttf') format('truetype'),
    url('../fonts/icons/clickadmin.svg#clickadmin') format('svg');
    font-weight: normal;
    font-style: normal;
}

.icn-question-mark-1::before{
    font-family: 'clickadmin' !important; vertical-align:middle;
    speak: none; content: '\7a';
}